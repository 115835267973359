
#information { background:#FAFAFA; margin: 0 0 20px 0; padding:0 0 60px 0;
                .llistat {
                         header h1 { font:600 58px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; padding: 0; text-transform: uppercase; }
                         header h2 { font:600 38px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; text-transform: uppercase; }
                         ul { margin:0; }
                         li { background:#fff; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); margin:0 0 20px; padding:25px;
                            figure   { position: relative;
                                     span { color:$color-white; margin:20px 0 0 0!important; padding:5px; position:absolute; text-align:center; z-index: 99999; }
                                     }
                            img      { margin:0; }
                            .large-6 { padding: 30px 30px; }
                            .large-2 { padding: 10px 0 0 0; }
                            header h3 { font-size:36px; line-height: 1.2em; }
                            .sala     { font-weight:600; margin:0; }
                            p         { font:400 14px $fontMain; line-height:1.2em; margin: 0; }
                            .preu_01  { border-bottom:1px solid #979797; padding:0 0 10px 0;
                                      span { display:block; }
                                      }
                            .preu_02  { margin:0 0 20px 0; padding:10px 0 0 0;
                                      span { display:block; }
                                      }
                            a.btnPrimary { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); color:#fff!important; font:600 22px $fontHeaders; margin:0; padding:5px 25px; text-transform: uppercase; }
                            }
                          .tabs         { background: none!important; border:none!important;
                                        li { background: none!important; border:none!important; box-shadow:none; margin:0!important; padding:0!important; }
                                        }
                          .tabs-panel   { background: none; border:none; }
                          .tabs-content { background:none; border:none; padding:0;
                                        ul { display:inline-block; margin:0 0 40px!important; width:100%; }
                                        }
                          #tabsprofessional   { background:none; margin:0 auto 20px; padding:0; text-align: center; width:auto; }
                         }
                .btnPrimary { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); border:none; color:#fff!important; font:600 22px $fontHeaders; margin:0; padding:5px 25px; text-transform: uppercase;
                            &:hover { background:$color-black; }
                            }
                .tabs-panel {
                            header h3   { font:600 28px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; text-transform: uppercase; }
                            .btnPrimary { margin-bottom:40px; }

                            }
                #normas     {
                            ul    { margin:0; }
                            ul li { box-shadow:none; font:600 16px $fontMain; line-height:1.4em; padding:0!important; text-align:left;
                                  strong { font-weight: 900; }
                                  }
                            }
                .category .accordion-content { font:600 16px $fontMain; line-height:1.4em; text-align:left; }
                }

#contacto       { background: $color-white; padding:60px;
                header h2 { font-size:42px!important; text-align: center; }
                header h3 { font:400 24px $fontHeaders; margin:0 0 10px 0; text-transform: uppercase; }
                .large-4  { padding:0 40px 0 0;
                          p { line-height:1.4em; margin:0 0 40px 0; }
                          ul { margin:0 0 40px 0;
                             li { box-shadow:none; color:$color-black; font-size:16px; margin:0; padding:4px 0; text-align: left; }
                             }
                          }
                label     { font:400 20px $fontHeaders; margin:0; text-transform: uppercase; }
                input     { border:none; border-bottom:1px solid $color-black; border-radius:0; box-shadow: none; padding:0;
                          :focus { border-bottom:2px solid $color-black!important; }
                          }
                form p    { margin:0 0 40px; }
                textarea  { border:none; border-bottom:1px solid $color-black; border-radius:0; box-shadow: none; margin:0; padding:20px 0 0 0; }
                .btnPrimary { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); border:none; color:#fff!important; font:600 22px $fontHeaders; margin:0; padding:5px 25px; text-transform: uppercase;
                            &:hover { background:$color-black; }
                            }
                }

#fiestas       { background: $color-white; padding:60px;
                header h2 { font-size:42px!important; text-align: center; }
                header h3 { font:400 24px $fontHeaders; margin:0 0 10px 0; text-transform: uppercase; }
                .large-4  { padding:0 40px 0 0;
                          p { line-height:1.4em; margin:0 0 40px 0; }
                          ul { margin:0 0 20px 0;
                             li { box-shadow:none; color:$color-black; font-size:16px; margin:0; padding:4px 0; text-align: left; }
                             }
                          }
                          ul        {
                                    li     { box-shadow:none!important; font:500 16px $fontMain; line-height:1.4em; margin:0!important; padding:0 0 15px!important; text-align: left; }
                                    }
                }

#eventos       { background: $color-white; padding:60px;
                header h2 { font-size:42px!important; text-align: center; }
                header h3 { font:400 24px $fontHeaders; margin:0 0 10px 0; text-transform: uppercase; }
                .large-4  { padding:0 40px 0 0;
                          p { line-height:1.4em; margin:0 0 40px 0; }
                          ul { margin:0 0 20px 0;
                             li { box-shadow:none; color:$color-black; font-size:16px; margin:0; padding:4px 0; text-align: left; }
                             }
                          }
                          ul        {
                                    li     { box-shadow:none!important; font:500 16px $fontMain; line-height:1.4em; margin:0!important; padding:0 0 15px!important; text-align: left; }
                                    }
                }

#quienes        { padding:80px 0;
                header h2 { font-size:42px!important; text-align: center; }
                aside header h3 { text-align:left; }
                }

#historic       { padding:80px 0;
                header h2 { font-size:42px!important; text-align: center; }
                ul li     { margin:0 10px 20px!important; width:23.58%;
                          figure { margin-bottom:10px; }
                          .sala  { display:inline-block; margin-bottom:10px!important; }
                          }
                }

#entradas       { padding:80px 0;
                header h2 { font-size:42px!important; text-align: center; }
                header h3 { font:400 34px $fontHeaders; margin:0 0 20px 0; text-transform: uppercase; }
                header h4 { font:400 24px $fontHeaders; margin:0 0 20px 0; text-transform: uppercase; }

                ul        {
                          li     { box-shadow:none!important; font:400 16px $fontMain; line-height:1.4em; margin:0!important; padding:0 0 15px!important; text-align: left; }
                          }
                div       { background: $color-white; padding:50px; }
                aside     { width:auto!important; }
                #related  { padding:40px; width: 32%!important;
                          p { text-align: center;
                            strong { display: block; font:600 24px $fontHeaders; margin:10px 0; text-transform:uppercase; }
                            }
                          .btnPrimary { border-radius:0; }
                          p.panel     { border:none; margin:0; }
                          }
                }

#normas         { padding:80px 0;
                aside     { background: #fff; display: inline-block; padding:60px 60px; }
                header h2 { font-size:42px!important; text-align: center; }
                header h3 { font:400 34px $fontHeaders; margin:0 0 20px 0; text-transform: uppercase; }
                }

#rrhh           { padding:80px 0;
                header h2 { font-size:42px!important; text-align: center; }
                header h3 { font:400 30px $fontHeaders; margin:0 0 20px 0; text-transform: uppercase; }
                .large-4 { padding:0 40px 0 0;
                         p { line-height:1.4em; margin:0 0 20px 0; }
                         }
                label    { font:400 20px $fontHeaders; margin:0; text-transform: uppercase; }
                input    { border:none; border-bottom:1px solid $color-black; border-radius:0; box-shadow: none; padding:0;
                         :focus { border-bottom:2px solid $color-black!important; }
                         }
                form p   { margin:0 0 40px; }
                select   { border:none; border-bottom:1px solid $color-black; border-radius:0; box-shadow: none; margin:0; padding:0; }
                input[type="file"] { border:none; margin:0; padding:10px 0; }
                }

#faqs           { padding:80px 0;
                aside     { background: none; padding:0; }
                .accordion{ background:none!important; }
                header h2 { text-align: center; }
                header h3 { font:400 24px $fontHeaders; margin:0 0 20px 0; text-transform: uppercase; }
                ul        { margin:0; }
                .accordion-item { border:none; background: $color-white; padding:20px!important; }
                .ui-accordion .ui-accordion-header { background: none!important; border:none; font:400 24px $fontHeaders; margin:0; padding:0!important; text-align: left; text-transform: uppercase;
                                                   a { text-decoration: none; padding:0; }
                                                   .ui-icon { display: none; }
                                                    }
                .accordion-title   {
                                   &:hover  { text-decoration: none!important; }
                                   &:active { text-decoration: none!important; }
                                   &:before { top: 20%; }
                                }
                .accordion-content { border:none; padding:20px 0;
                                   p { font-size:17px!important; line-height: 1.2em!important; margin:0 0 8px 0!important; }
                                   }
                }

.tabs-panel aside{ background: #fff; display: inline-block; padding:60px 60px; width:100%; }
