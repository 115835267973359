
#agenda         { background:#FAFAFA; margin: 0 0 20px 0; padding:60px 0;
                .llistat {
                         header h1 { font:600 58px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; padding: 0; text-transform: uppercase; }
                         header h2 { font:600 38px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; text-transform: uppercase; }
                         ul { margin:0; }
                         li { background:#fff; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); margin:0 0 20px; padding:25px; position: relative;
                            figure   { position: relative;
                                     span { color:$color-white; display:block; margin:20px 0 0 0!important; padding:5px; position:absolute; text-align:center; width:auto!important; z-index: 99999; }
                                     }
                            img      { margin:0; }
                            .large-6 { padding: 0 30px 30px 30px; }
                            .large-2 { padding: 10px 0 0 0; }
                            header h3 { font-size:36px; line-height: 1.2em; }
                            .sala     { display:inline-block; font-weight:600; margin:0; width:100%; }
                            p         { font:400 16px $fontMain; line-height:1.2em; margin: 0; }
                            .preu_01  { border-bottom:1px solid #979797; padding:0 0 10px 0;
                                      span { display:block; }
                                      }
                            .preu_02  { margin:0 0 20px 0; padding:10px 0 0 0;
                                      span { display:block; }
                                      }
                            a.comprar { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); color:#fff!important; font:600 22px $fontHeaders; margin:0; padding:5px 25px; position:absolute; right:60px; text-transform: uppercase; top:43%; }
                            }
                          .tabs         { background: none!important; border:none!important;
                                        li { background: none!important; border:none!important; box-shadow:none; margin:0!important; padding:0!important; }
                                        }
                          .tabs-panel   { background: none; border:none; }
                          .tabs-content { background:none; border:none; padding:0;
                                        ul { display:inline-block; margin:0 0 40px!important; width:100%; }
                                        }
                          #tabsagenda   { background:none; margin:0 auto 20px; padding:0; text-align: center; width:auto; }
                          .event_status { display:inline-block; font-size:18px; margin: 0 0 10px 0; padding:10px 20px; width:auto; }
                         }
                .concert { background:#D95F0A; }
                .zoco    { background:#8B0AD9; }
                .sudor   { background:#0AD9CF; }
                }

#fitxa          { padding:60px;
                p a         { border-bottom:1.5px solid $colorSecondary; font-weight:900; text-decoration: none; }
                .short_description { border-bottom:1px solid #000; font:400 28px $fontMain; letter-spacing: -0.04em; line-height: 1.4em; padding-bottom:15px; }
                header h3   { font:600 52px $fontHeaders; line-height: 1.2em; text-transform: uppercase; }
                header h4   { font:600 28px $fontHeaders; line-height: 1.2em; margin:40px 0 20px; text-transform: uppercase; }
                #info       { background:$color-white; padding:60px;
                            header h3 { color:$colorSecondary; font-size:38px; text-align: left; }
                            div       { border-bottom:1px solid #D8D8D8; display: inline-block; margin-bottom: 25px; padding:0 0 25px 0; width:100%;
                                      p { font:400 18px $fontMain; line-height: 1.4em; margin-bottom:0;
                                        a { border:none; font:400 18px $fontMain; }
                                        }
                                      ul  { display: inline-block; margin:0 0 0; width:100%;
                                          li { font-size: 18px; text-align: left;
                                             span   { font-size:15px; }
                                             strong { display: block; font-size:16px; font-weight:600; margin:8px 0 0 0; }
                                             &:first-child { padding:10px 10px 10px 0; }
                                             &:nth-child(2){ border-left:1px solid #D8D8D8; padding:10px 0 10px 15px; }
                                             }
                                          }
                                      .pdf { margin:20px 0 0 0;
                                           a { font-weight:600;
                                             img { margin:-2px 0 0 10px; width:22px; }
                                             }
                                           }
                                      }
                            }
                #band       { font:400 18px $fontMain; line-height: 1.4em; padding-right:60px; }
                .compra     { border:none!important; margin:0!important; padding:0!important;
                            li     { border:none!important; display: block!important; padding:0!important; width:100%;  }
                            a      { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); border:none; color:#fff!important; font:600 22px $fontHeaders; margin:10px 0; padding:15px 25px; text-transform: uppercase; width:100%;
                            &:hover{ border: none; }
                            }
                            }
                }

.nextevents     { background: $color-white; padding:60px 0;
                .eventscarousel { display: inline-block; margin:20px 0 60px 0!important;
                                .slick-slide { padding: 0 10px; }
                                }
                  header h2 { font:600 52px $fontHeaders!important; line-height:1.22em; margin:0 0 30px 0; text-align: center; text-transform: uppercase; }
                  div + div { text-align: center;
                        a:hover { opacity:1; }
                        figure    { margin-bottom:0; max-height:170px; overflow: hidden;
                                  &:hover { opacity:1!important; }
                                  }
                        img       { margin-bottom:20px; transition: transform .5s;
                                  &:hover { opacity:1!important; transform: scale(1.1); }

                                  }
                        header h3 { font:600 32px $fontHeaders; text-transform: uppercase; }
                        .sala     { color:#3E3E3E; font:600 10px $fontMain; letter-spacing: 0.08em; margin:0 0 15px; text-transform: uppercase; }
                        .date     { color:$colorSecondary; display: inline-block; font:600 18px $fontHeaders; margin-bottom:20px; text-transform: uppercase; width: 100%; }
                      }
                      div { text-align: center;
                            a:hover { opacity:1; }
                            figure    { margin-bottom:0; max-height:170px; overflow: hidden;
                                      &:hover { opacity:1!important; }
                                      }
                            img       { margin-bottom:20px; transition: transform .5s;
                                      &:hover { opacity:1!important; transform: scale(1.1); }

                                      }
                            header h3 { font:600 32px $fontHeaders; text-transform: uppercase; }
                            .sala     { color:#3E3E3E; font:600 10px $fontMain; letter-spacing: 0.08em; margin:0 0 15px; text-transform: uppercase; }
                            .date     { color:$colorSecondary; display: inline-block; font:600 18px $fontHeaders; margin-bottom:20px; text-transform: uppercase; width: 100%; }
                          }
}

.responsive-embed { margin:40px 0; padding-bottom:60%!important; }

.event_status     { background:$colorPrimary; color: $color-white; display: inline-block; font:600 18px $fontHeaders; letter-spacing:0.02em; padding:10px 35px; text-align: center; text-transform: uppercase; width:auto; }
.priceInfo        { color: $color-white; display: block; font:600 14px $fontMain!important; letter-spacing: -0.02em; text-transform: none!important; }


.caption .event_status { background:#D22B2B!important; color:$color-white; font:600 28px $fontHeaders; }
