
#clubs         { background:#FAFAFA; margin: 0 0 20px 0; padding:60px 0;
                .llistat {
                         header h1 { font:600 58px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; padding: 0; text-transform: uppercase; }
                         header h2 { font:600 38px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; text-transform: uppercase; }
                         p         { font:400 16px $fontMain; line-height:1.2em; margin: 0 0 40px; }
                         ul { margin:0;
                            li header h3 { font-size:36px!important; line-height: 1.2em; }
                            }
                         li { background:#fff; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); margin:0 15px 15px; padding:25px; width: 31.15%;
                            figure   { margin:0 0 10px 0; position: relative;
                                     span { color:$color-white; margin:0 0 20px 0!important; padding:5px; position:absolute; text-align:center; z-index: 99999; }
                                     }
                            img      { margin:0; }
                            header h3 { font-size:36px!important; line-height: 1.2em; }
                            .sala     { font-weight:600; margin:0; }
                            p         { font:400 16px $fontMain; line-height:1.2em; margin: 0 0 20px; }
                            a.btnPrimary { background:none; border-radius:none; font:400 20px $fontHeaders; margin:0; padding:0; text-transform: uppercase; }
                            p.center  { margin:30px 0 0; }
                            }
                          }
                .concert { background:#D95F0A; }
                .zoco    { background:#8B0AD9; }
                .sudor   { background:#0AD9CF; }
                }

#club           { padding:60px 0;
                .large-3    { background:$color-white; padding:40px; }
                p a         { border-bottom:1.5px solid $colorSecondary; font-weight:900; text-decoration: none; }
                header h3   { font:600 52px $fontHeaders; line-height: 1.2em; text-transform: uppercase; }
                header h4   { font:600 28px $fontHeaders; line-height: 1.2em; margin:40px 0 20px; text-transform: uppercase; }
                #info       { background:$color-white; padding:60px;
                            header h3 { color:$colorSecondary; font-size:38px; text-align: left; }
                            div       { border-bottom:1px solid #D8D8D8; display: inline-block; font:400 18px $fontMain; line-height: 1.4em; margin-bottom: 25px; padding:0 0 25px 0; text-align:left; width:100%;
                                      p { font:400 18px $fontMain; line-height: 1.4em; margin-bottom:0;
                                        a { border:none; font:400 18px $fontMain; }
                                        }
                                      ul  { margin:0;
                                          li { border-right:1px solid #D8D8D8; font-size: 18px; text-align: left;
                                             strong { display: block; font-weight:600; margin:0 0 10px 0; }
                                             &:first-child { padding:0; }
                                             &:last-child  { border:none;   }
                                             }
                                          }
                                      .pdf { margin:20px 0 0 0;
                                           a { font-weight:600;
                                             img { margin:-2px 0 0 10px; width:22px; }
                                             }
                                           }
                                      }
                            }
                .slideshow_interior { height: 580px; overflow:hidden; text-align: center;
                                    .caption { left:0; max-width:none; text-align: center; top:25%; width:100%;
                                             .subtitle { color: $color-white; font-size:24px; text-align: center; }
                                             header h3 { color:$color-white; font:600 22px $fontHeaders; line-height: 1.2em; margin:0 0 20px; text-transform: uppercase;  }
                                             }
                                    }
                #description        { padding:0 60px; }
                .djs                { padding-bottom:0!important;
                                    ul { margin:0;
                                        li { border-bottom: 1px solid #D8D8D8; border-right:none!important; padding:7px 0!important;
                                           &:last-child  { border:none!important; padding-left:0!important; }
                                           }
                                        li:nth-child(2) { border-left:none!important; }
                                       }
                                    }
                .social             { border-bottom:none!important;
                                    ul li { border:none!important; padding:0!important; }
                                    }
                .llistat            {
                                    ul {
                                       li header h3 { font-size:36px!important; line-height: 1.2em; }
                                       }
                                    }
                }
