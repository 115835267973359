.overlay                    { background:#000; }
.overlay ul li 						  { border-bottom:.5px solid #fff; width:100%!important; }
.overlay ul li:first-child  { padding:0 .5rem!important; }
.overlay ul li a					  { color:#fff!important; font-size:16px!important; font-weight: 400!important; padding:10px 20px; }
.overlay-door.open nav li a { padding:10px 20px; }
#trigger-overlay            { color:#000; font:300 14px 'Work Sans', sans-serif; line-height: 24px; text-transform: uppercase; }
.overlay figure             { margin:40px 0 20px 0!important; }
.overlay-door nav           { height: 50%; position:relative; top:160px; }
.overlay #mobile_footer     { bottom:50px; left:20%; position:absolute; text-align:center; }
.overlay #mobile_footer ul#social { text-align:center; }
.overlay #mobile_footer ul#social li { padding:0 10px!important; text-align:center; width:auto!important; }
.overlay #mobile_footer ul#social li:first-child { padding:0 10px!important; text-align:center; width:auto!important; }
.overlay #mobile_footer ul#social li a { font-size:20px!important; }

.overlay #language_selector li { border-bottom:none!important; width:50%!important; }
.overlay #language_selector li a{ font-size:12px; }
.overlay #language_selector li:first-child { text-align:right; }
.overlay #language_selector li:last-child { text-align:left; }

.overlay #language_selector li a { background:rgba(255,255,255,.25)!important; color:#fff!important; display: inline-block!important; min-width:60px; text-align:center; width:auto; }
.overlay #language_selector li a.active { background:#fff!important; color:#000!important; display: inline; width:auto; }
