@charset "utf-8";
/* CSS Document - CSS per a dispositius mòbils */

/* --------------------------
CSS SALA SALAMANDRA
https://www.salmandra.cat/
Creat per Signia - Abril 2024
----------------------------- */


/* --------------------------------------------------------------------------------------------------- */
/* IPHONE X PRO MEDIA QUERIES (PORTRAIT) ------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */

@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {

.section-header { max-height: 220px;
                img { min-height:220px; }
                }
#topbar      { min-height:225px; padding:0; }
#logotip     { padding:15px 0 20px 40px; text-align: center; width: auto;
             img { max-width: 160px; }
             }
.lang        { float:left; margin:20px 0 0 -10px !important; width:auto!important;
             li { padding:0 5px!important; width:auto!important; }
             a  { color:$color-black!important; font-size:16px!important; letter-spacing: -0.01em; }
             }
#mainnav     { margin:0; padding: 0;
             ul { margin:0; text-align: center;
                li            { padding:0 10px; }
                li:last-child { float:left; margin: 30px 0 20px 0; padding:0!important;
                              input[type="search"] { background: rgba(0, 0, 0, 0.05); width:240px; }
                              }
                li.active a   { border-bottom: 2px solid #e75400; padding-bottom: 3px; }
                }
             a  { color:$color-black!important; font-size:16px!important; letter-spacing: -0.01em; }
             }
.slideshow   { margin:0!important;
             .caption { bottom:20px; left:0; text-align: center; max-width:none; width:100%;
                      .date     { font-size:15px; margin-bottom:10px; text-align: center; }
                      header h2 { font-size:28px; line-height: 1em; text-align: center; }
                      }
             }
.slideshow .slick-next,
.slideshow .slick-prev { background-size:32px; height: 40px !important; display: none!important; }

.slideshow_interior { margin:-60px 0 0 0!important;
                    .caption { bottom:210px; left:0; text-align: center; max-width:none; width:100%;
                             .date     { font-size:15px; margin-bottom:10px; text-align: center; }
                             header h2 { font-size:28px; line-height: 1em; text-align: center; }
                             }
                    .buy     { background:$color-black; position: inherit;
                             .sala      { border-bottom: 1px solid #fff; padding-bottom:10px; }
                             .sala span { font-size:14px; }
                             .prices    { padding-top:10px; text-align: left; }
                             }
                    }

header h1    { padding:10px 0; }
.llistat     { padding:20px 0;
             ul li  { margin-bottom:10px!important;
                    img { margin:0!important; }
                    }
             }
#manifest    { padding: 40px 10px 40px;
             p { line-height: 1em; max-width:none; }
             }
#onsom       { padding:40px 0; }
.homegallery .slick-next { height: 35px !important; width: 35px !important; }




/* AGENDA */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
#agenda      { padding:0; }
#agenda .llistat {
                 header h1 { font-size: 48px; }
                 header h2 { font-size: 38px; text-align:center; margin:0 0 10px 0; }
                 .large-6  { padding:20px 0 0 0!important; }
                 a.comprar { display: inline-block; padding: 7px 25px; position: inherit!important; text-align: center; width: 100%; }
                 .tabs li  { width:100%;
                           a { padding: 10px 0 !important; }
                           }
                 }





/* FITXA */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */

#fitxa          { padding: 40px 15px;
                #band  { padding:0; }
                #info  { padding: 30px;
                       div ul li:nth-child(2) { border-left:none; border-top: 1px solid #D8D8D8; padding: 10px 0 10px 0; }
                       }
                }
.nextevents     { padding:40px 0;
                header h2 { font-size:48px!important; line-height: 1em!important; margin:0 0 10px 0; }
                }





/* CLUBS */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */

#clubs          { padding-top:0; padding-bottom:0;
                .llistat {
                         header h1  { font-size: 48px; }
                         li         { margin:0 0 10px; padding:20px; width: inherit; }
                         }
                }





/* PROFESSIONAL */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */

#sala, #sala2 { margin: 0; padding: 30px; }
#sala .right,
#sala2 .right { padding:0; }
#tabsalas .tabs-title > a { width:100%; }
#formulario   { padding: 20px;
              .large-4    { padding:0; }
              .btnPrimary { width:100%; }
              }
.tabs-panel aside { padding: 30px; }
#virtual,
#virtual2,
#riders,
#riders2      { padding:0; }





/* INFORMACIÓ */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */

.llistat .tabs li { margin-bottom: 1px; width:100%; }
#quienes          { padding:40px 20px; }
#fiestas          { padding: 40px 20px;
                  .large-4 { padding: 0; }
                  #description { padding:0; }
                  }
#eventos          { padding: 40px 20px;
                  .large-4 { padding: 0; }
                  #description { padding:0; }
                  }
#faqs             { padding: 40px 20px;
                  .ui-accordion .ui-accordion-header { font-size: 18px; }
                  }
#normas           { padding: 40px 20px;
                  aside { padding:30px; }
                  }
#information      {
                  .btnPrimary { width:100%; }
                  }




/* CONTACTO */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */

#contacto       { padding:40px;
                .large-4    { padding:0; }
                form p      { margin:0 0 20px 0; }
                .btnPrimary { width:100%; }
                }





/* FOOTER */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */

footer       { padding:40px 0;
             ul { padding-bottom: 10px; }
             #newsletter { max-width: inherit; padding: 40px 20px;
                         p  { line-height: 1.1em; }
                         }
             #xxss       { padding: 0px 0 20px 0; }
             #copyright  {
                         p       { margin:0 0 10px 0; text-align: center; }
                         ul      { margin:0 0 20px 0;
                                 li { padding:0 6px; }
                                 }
                         ul li a { font-size:12px; }
                         ul li:last-child { float:left!important; }
                         }
             }
}
