
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------- LAPTOP SCREEN RESOLUTION --------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-device-width : 768px) and (max-device-width : 1366px) {

}


@media only screen and (min-device-width : 1050px) and (max-device-width : 1650px) {

}





/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* MAC BOOK PRO 13 INCH (ISSUES) */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 1200px) and (max-width: 1600px) {

}




/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* MAC BOOK (ISSUES) */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 900px) and (max-width: 1440px) {

}




/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP 1280 * 1024 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 1024px) and (max-width: 1280px) {



}



/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP 1440 * 900 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 900px) and (max-width: 1440px) {

.slideshow .caption { bottom: 120px!important; left: 150px!important; }
.eventscarousel .slick-prev { left:7px!important; }
.eventscarousel .slick-next { right:7px!important; }

}



/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP WIDTH MDPI SCREEN 1280 * 800 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 800px) and (max-width: 1280px) {
#clubs .llistat li { margin: 0 10px 15px!important; }

}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP WIDTH TOUCH 1280 * 950 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-width: 950px) and (max-width: 1280px) {

}



/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/* LAPTOP 1680 * 1050 */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------ */

@media only screen and (min-device-width : 1050px) and (max-device-width : 1680px) {

}
