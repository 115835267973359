/* Layout */
html            { -moz-osx-font-smoothing:grayscale; -webkit-font-smoothing:antialiased; line-height: 28px; scroll-behavior: smooth; }
body            { background:$color-palegrey; font:400 16px $fontMain; }
.row            { margin:0 auto!important; max-width:90rem!important; }
.grid-container { max-width:90rem; }
figure          { margin:0;
                &:hover { opacity:1;  }
                }
.center         { text-align:center; width:100%;
                p { margin:0 0 40px 0; text-align:center; }
                }
.right          { float:right!important; text-align: right; }
.left           { float:left; text-align: left; }
a:hover         { opacity:0.5; }
ul li           { list-style-type: none!important; }

#mainnav        { margin:20px 0 0 0; text-align: right;
                ul { display: inline-block; text-align: right;
                   li { text-align: center; width:auto; }
                   li.active a { border-bottom:3px solid rgb(231,84,0); padding-bottom:5px;  }
                   li:last-child { float: right; width: auto;
                                 form { margin:-10px 0 0 0; }
                                 input { border:none; border-radius:0; border-top-left-radius: 5px; border-bottom-left-radius: 5px; box-shadow:none; float:left; margin:0; }
                                 input[type="search"] { float:left; width:250px; }
                                 input[type="submit"] { background:$color-black; border-top-right-radius: 5px; border-top-left-radius: 0px; border-bottom-left-radius: 0px; color:$color-white; border-bottom-right-radius: 5px; padding:8px 25px; font:600 20px $fontHeaders!important; letter-spacing: 0.04em; text-transform: uppercase!important;
                                                      &:hover { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); }
                                                      }
                                 i.fa { }
                                 }
                   }
                a  { color:$color-white!important; font:600 20px $fontHeaders!important; letter-spacing: 0.04em; text-transform: uppercase!important; white-space: nowrap;
                   &:hover { text-decoration:none; }
                   }
                }
.lang           { margin:20px 0 0 0!important;
                a { color:$color-white!important; font:600 18px $fontHeaders; text-transform: uppercase; }
                li            { border-right: 1px solid #fff; }
                li:last-child { border:none; }
                }
.interiors .lang {
                 li { border-right: 1px solid #000; }
                 li:last-child { border:none; }
                 }

:target:before  { content: ""; display: block; height: 100px; margin: -100px 0 0; }

#topbar         { display: inline-block; padding:25px 0; position:relative; width:100%; z-index:9999;
                figure#logo            { margin:27px 0 0 50px; width: 260px; }

                [role="menu"]          { border-radius: 0; display: block; margin-bottom: auto; margin-top: auto; width: 100%; }
                [role="complementary"] { display: inline-block; float: left; font-size:12px; line-height: 16px; margin-right: 10px; text-align: center; text-transform: uppercase; width: 30px; }
                [role="region"]        { display: block; font-size: 10px; opacity: .6; text-transform: uppercase }
                [role="img"]           { margin-left:auto; opacity: 1; transition: transform .4s cubic-bezier(.23,1,.32,1); }
                [aria-expanded="true"] [role="img"] { transform: rotate(45deg); }
                span [role="main"]     { font-size: 12px; font-weight: 600; text-transform: uppercase; }

                #language_selector     { display: flex; height: auto!important;
                                       .opens-right img { min-width:18px; }
                                       .dropdown { border:none;
                                       li.is-dropdown-submenu-parent > a { font-size:12px; margin:0; padding:29px 25px 29px 10px; text-transform: uppercase; }
                                       li.is-dropdown-submenu-parent > a:hover,
                                       li.is-dropdown-submenu-parent > a:focus { color:$color-white; opacity:1; }
                                       li.is-dropdown-submenu-parent > a::after { border-color: #fff transparent transparent;}
                                               ul.menu { border:none; max-width:200px; padding:5px 0 0 0; z-index:9999;
                                                       li { display: inline-block; }
                                                         a   { color:$color-black; font-size: 13px; font-weight: 600; padding:10px 10px!important; text-transform: uppercase; }
                                                         img { margin-right:8px; }
                                                         }
                                                 }
                                      }
                }

#topbar.interiors { background: $color-white; -webkit-box-shadow: 0px 10px 13px -5px rgba(0,0,0,0.20); -moz-box-shadow: 0px 10px 13px -5px rgba(0,0,0,0.20); box-shadow: 0px 10px 13px -5px rgba(0,0,0,0.20);
                  li a { color:$colorPrimary!important; }
                  input[type="search"] { background:rgba(0,0,0,.05); min-height:40px; }
                  }

.box           { margin: 0 auto;max-width: 37em; text-align: center; }

.llistat       { padding:40px 0;
               ul li { margin-bottom: 50px; text-align: center;
                     a:hover { opacity:1; }
                     figure    { max-height:185px; overflow: hidden;
                               &:hover { opacity:1!important; }
                               }
                     img       { margin-bottom:20px; transition: transform .5s;
                               &:hover { opacity:1!important; transform: scale(1.1); }

                               }
                     header h3 { font:600 32px $fontHeaders; line-height:1em; text-transform: uppercase; }
                     .sala     { color:#3E3E3E; font:600 10px $fontMain; letter-spacing: 0.08em; margin:0 0 10px; text-transform: uppercase; }
                     .date     { color:$colorSecondary; display:inline-block; font:600 18px $fontHeaders; margin-bottom:20px; text-transform: uppercase; width:100%; }
                   }
               p   { color:#000; font-weight: 600;  letter-spacing: normal;
                   img { margin-left:15px; max-width: 22px; }
                   a   {
                       &:hover { color:$colorSecondary; text-decoration: none; }
                   }
                   }
               }

#onsom      { background: #FAFAFA; padding:80px 0;
            header h3 { font:42px $fontHeaders; margin:0 0 20px 0; text-transform: uppercase;
                      img { margin:-10px 0 0 10px; max-width:11px; }
                      }
            p         { font:600 18px $fontMain; letter-spacing: -0.02em; line-height: 1.4em;
                      .mail { border-bottom:1px solid $colorSecondary; }
                      }
            }

.slideshow  { margin:-132px 0 0 0!important;
            img { position: relative; }
            .caption { bottom: 300px; max-width: 40%; position:absolute; z-index:99999;
                     .date     { color:#fff; font:600 22px $fontHeaders; text-shadow: 4px 4px 12px rgba(0,0,0,0.25); text-transform: uppercase; }
                     header h2 { color:#fff; font:600 54px $fontHeaders; text-shadow: 4px 4px 12px rgba(0,0,0,0.25); text-transform: uppercase;
                               a { color: #fff!important; text-decoration: none!important; }
                               }
                     }
            .buy     { background:rgba(0,0,0,.4); bottom: 0; padding:22px 0; position:absolute; width:100%; z-index:999999;
                     p         { margin:0; }
                     .sala     { color:#fff; font:600 22px $fontHeaders; margin:0;  text-transform: uppercase; }
                     .btn      { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); color:#fff; font:600 22px $fontHeaders; margin:0; padding:5px 25px; text-transform: uppercase; }
                     }
            .slick-current { position: relative; }
            }


.slideshow_interior  { margin:-185px 0 0 0!important;
                     img { height:100%; max-height: 100vh; position: relative; width:100%;}
                     .caption { bottom: 300px; max-width: 40%; position:absolute; z-index:99999;
                              .date     { color:#fff; font:600 22px $fontHeaders; text-shadow: 4px 4px 12px rgba(0,0,0,0.25); text-transform: uppercase; }
                              header h2 { color:#fff; font:600 54px $fontHeaders; text-shadow: 4px 4px 12px rgba(0,0,0,0.25); text-transform: uppercase; }
                              }
            .buy     { background:rgba(0,0,0,.4); bottom: 0; padding:22px 0; position:absolute; width:100%; z-index:999999;
                     p         { margin:0!important; }
                     .sala     { color:#fff; font:600 22px $fontHeaders; margin:0; text-transform: uppercase;
                               span { display:block; font:600 17px $fontMain; text-transform: none; }
                               }
                     .btn      { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); color:#fff; font:600 22px $fontHeaders; margin:0; padding:5px 25px; text-transform: uppercase; }
                     .prices span { color:$color-white; font:600 22px $fontHeaders; margin:0 15px 0 0; text-transform: uppercase; }
                     }
            .slick-current { position: relative; }
            }

.carousel   { padding: 40px 0;
            div { padding:0 10px; }
            }


.slideshow_sala  { margin:0 0 30px 0!important; }

footer      {
            background:$colorPrimary; color:hsla(0,0%,100%,.5); padding:100px 0 20px;
            nav a { display:block; font:13px $fontMain; line-height:36px; text-transform: uppercase;
                  &:hover { color:$color-sunflower; opacity:1; }
                  }
            header h3             { color:$color-white; font:26px $fontHeaders; margin:0 0 40px 0; text-transform:uppercase; }
            ul          { border-bottom:1px solid $color-white; padding-bottom:60px; }
            #newsletter { margin:0 auto; max-width:32%; padding:60px 0;
                        header h3 { color:$color-white; font:48px $fontHeaders; text-transform:uppercase; }
                        p         { color:$color-white; font:400 20px $fontMain; line-height: 1.4em; margin:0; }
                        a         { color: $color-white!important; border-bottom:1px solid $color-white; }
                        #contact_form {
                                      input  { color:#fff; }
                                      #subscriber_add_save:hover { background:$color-white; color:$color-black!important; }
                                      }
                        }
            #xxss       { padding:0 0 60px 0; text-align: center;
                        p { font:400 16px $fontMain; line-height: 1.4em; text-align: center; text-transform: uppercase; }
                        ul  { border:none; display: inline-block; margin:0 auto; padding-bottom:0; text-align: center; width:auto;
                            li { display: inline; padding:0 14px!important;
                               a i { color:#fff; font-size:22px; }
                               }
                            }
                        }
            #copyright { border-top:1px solid hsla(0,0%,100%,1); padding-top:30px; width:100%;
                       ul { border:none; margin:0 auto; padding:0; text-align: center;
                          li   { opacity:1; text-align: center; width: auto;
                                a { color:$color-white!important; opacity:1; }
                               }
                          :last-child { float:none!important; text-align: center!important;
                                      a { text-transform: none!important; }
                                      }
                          }
                        p       { color: $color-white; display: inline-block; font:400 14px $fontMain; margin: 0; }
                       }
            #contact_form {
                          input[type="email"] { background:none; border-radius:0; border-top:none; border-left:none; border-right:none; font:400 18px $fontMain; margin:0 0 30px 0; padding-left:0; }
                          .btn { border:1px solid $color-white; font:600 32px $fontHeaders; padding:5px 25px; text-transform: uppercase; width: 100%; }
                          }
            }



/* Fonts */
a            { color: $color-white; }
header h1    { color: $colorPrimary; font:48px $projectType; line-height:48px; text-transform: uppercase; }
h2           { color: $colorPrimary; font:44px $fontMain; line-height:1; text-transform: uppercase; }
h3           { }
h4           { color: $colorPrimary; font:13px $fontMain; text-transform: uppercase; }
h5           { color: $colorPrimary; font:12px $fontMain; text-transform: uppercase; width:100%; }
p            { color: $colorPrimary; font:400 16px $fontMain; line-height: 1.5; margin: 20px 0; text-align: left; width:100%; }
p:first-child{ margin-top: 0; }
ul li        { color: $colorPrimary; font:400 14px $fontMain; }


/* General */
ul                   { list-style-type:none; margin:0; }
ul.imagebox          { margin-bottom:60px; margin-top:55px; justify-content: space-between;
                     li img       { left: 0; position: relative; top: 0; width: 100%; }
                     li div.main  { bottom: 50px; left: 50px; position: absolute; }
                     li           { position:relative; }
                     li h2        { color: $color-sunflower; font-size:32px; line-height: 1; margin: 0 0 5px; /* max-width: 4em; */ }
                     li p         { color: $color-white; font: 600 12px $fontMain; margin: 0; text-transform: uppercase; }
                     a            { border-bottom:none!important; color:$color-white!important; line-height: normal!important;
                                  &:hover { opacity:1; }
                                  }
                   }





/* Botones */
.btnPrimary   { align-items: center; background:$btnPrimary; border: 1px solid transparent; border-radius: 2em; color:$colorPrimary!important; display:inline-block!important; font:600 13px "ProximaNovaSemibold", sans-serif; justify-content: center; line-height: normal; margin-bottom: 15px; padding:1.33em 1.66em 1.2em; text-transform: uppercase; vertical-align: top; white-space: nowrap;
              img { height: 1.4em; margin-right: .5em; object-fit: contain; }
              }
.btnSecondary { border:1px solid $color-sunflower; border-radius: 2em; color:$colorPrimary!important; font:600 13px "ProximaNovaSemibold", sans-serif; padding:1.33em 1.66em 1.2em; text-transform: uppercase; vertical-align: middle; white-space: nowrap; }
.btnSecondary:hover { background:$color-sunflower!important; opacity:1; }
.more         { border-bottom: 2px solid $color-sunflower; color: $colorPrimary; display: block; font: 600 12px $fontMain; height: auto!important; line-height: 1.2em; margin: 20px; padding:0; text-transform: uppercase; width:auto!important; }
.btnback      { background:$color-white; border-radius: 1.5em; color: $colorPrimary; font-size: 13px; font-weight: 500; left: 20%; line-height: 1; padding: .85em 1.25em .75em; position: absolute; text-transform: uppercase; top: 185px; z-index:99;
              img     { float:left; transform: rotate(90deg); height: 14px; margin: -1px 3px 0 -4px; vertical-align: middle; width: 14px; }
              &:hover { background:$color-sunflower; color:$color-white; opacity:1;
                      img { filter: invert(100%); }
                      }
              }


/* Tabs */
.tabs-title > a                       { background: $color-white!important; font:400 16px $fontMain!important; margin:0 10px; min-width:160px; text-decoration: none; text-transform: uppercase; padding:5px 0!important; }
.tabs-title > a:hover                 { background: $colorPrimary!important; color:$color-white!important;   }

.tabs-title > a[aria-selected='true'] { background: $colorPrimary!important; color:$color-white!important; opacity:1; }




/* Pagination */
.pagination          { margin:0!important; padding:0!important; text-align: center;
                     a        { border:none; border-radius:0!important; box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.04); color: $colorPrimary; font-weight: 600; text-align: center; padding:15px 20px!important; text-transform: uppercase;
                     a:hover  { background:rgb(231,84,0)!important; color: $color-white!important; }
                     }
                     .current { background:rgb(231,84,0)!important; border:none; color: $color-white; font-family: $fontHeaders; text-align: center; padding:15px 23px!important; text-transform: uppercase; }
                     li       { border-radius:0!important; box-shadow: none!important; margin:0!important; padding:0!important; width:initial!important; }
                     }
.pagination-previous { background: none!important; border-radius: 0; padding:15px 20px!important; text-transform: uppercase;
                     a { padding:15px 20px!important; }
                     img { transform: rotate(180deg); }
                     .disabled         { background:none!important; border-radius: 0; opacity: .25; padding:15px 20px!important; }
                     .disabled::before { display: none; }
                     a::before         { display: none; }
                     }
.pagination-next     { background: none!important;
                     a { box-shadow: none!important; }
                     a::after { display: none; }
                     }

                    .pagination .disabled { padding:10px 20px!important;}

.pagination a:hover { background:rgb(231,84,0)!important; color: $color-white!important; text-decoration: none; }
nav#pagination ul li{ margin:0!important; }

/* GALLERY */
.gallery           { background:$color-navy; padding: 60px 0 120px; overflow-x: hidden;
                   p { color:$color-white; margin:0; text-align:center; }
                   }


/* FORM */
#form              { background: $color-white; padding:100px 0; }

div#form-info { background: $color-white; padding:100px 0; width: 100%;
              a          { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
              header h2  { max-width: 400px; }
              p          { }
              p.short    { max-width: 400px; }
              ul li      { margin-top:1.8em; position: relative;
                         span { bottom:25px; height: 16px; position: absolute; right: 10px; width: 16px; }
                         }
              input      { box-shadow: none; border-bottom:1px solid rgba(0,21,33,.15)!important; border:none; box-sizing: border-box; font-size:18px; padding:.75em 0; }
              input:focus{ border-bottom:1px solid rgba(0,21,33,1)!important; box-shadow: none; }
              textarea   { border-bottom:1px solid rgba(0,21,33,.15)!important; border-top:none; border-left:none; border-right:none; box-shadow: none; font-size:18px; padding-left:0; }
              select     { background:none; border-left:none; border-right:none; border-top:none; font-size:18px; padding-left:0; }
              p.legal label label { float:right; width:95%; }
              label > [type="checkbox"] { margin-bottom:0; }
              .btnPrimary{ min-width: 120px; }
              }


#common       { article   { margin:80px 0; }
              header h2 { margin-bottom:40px; }
              }


.section-header { background:$colorPrimary; background-size: cover; margin-top:-3px; position:relative; /* z-index: -1; */
                figure      { height:325px; overflow: hidden; }
                img         { width:100%; }
                header      { margin-top:125px; top:0; }
                header h1   { color:$color-white; font-size:48px; text-align:center;
                            span  { display: block; font:600 18px $fontMain; margin:1em 0; text-transform: uppercase; }
                            }
                .grid-container { left:0; max-width:none; position:absolute; top:0; width:100%; }
                ul          { margin:20px auto 0; text-align: center;
                            li { display: inline; margin: 0 10px;
                               a { min-width: 140px; }
                               .btnPrimary:hover { background: $colorPrimary; color:$color-white!important; opacity:1; }
                               }
                            }
                }

p.entradeta     { font-size:18px; margin:0 auto 3em auto; text-align: center; }

#blocs         {
              .content { margin:0; max-width: none; padding:50px 40px; }
               a     { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
               aside { margin:0 0 100px 0!important;
                     p { font-size: 16px; line-height: 26px; margin: 1em 0; }
                     }
               hr    { border:none; margin:50px 0; }
               ul li { box-shadow:0 2px 4px 0 rgba(0,0,0,.04); background: $color-white; margin-top:25px; padding:0; position: relative;
                     &:before { background:none; }
                     figure { text-align: right;
                            img { margin:0 -15px 0 0; object-position:center top; width: 100%; }
                            }
                     header h4 { font-size: 32px; font-family: $fontMain; text-transform:uppercase; }
                     header h5 { font-size: 46px; font-family: $fontMain; text-transform:uppercase; }
                     ul    { margin:0 0 30px 0;
                           li       { box-shadow:none; font-size: 16px; line-height: 26px; margin:0; padding:0 0 0 25px; position: relative;
                                    ::before { background: #0081cd; border-radius: 3px; content: ""; display: block; height: 6px; left: 5px; position: absolute; top: 10px; width: 6px; }
                                    p        { margin:0; }
                                    }
                           }
                     }
               .plus { align-items: center; border: 1px solid #fccf00; border-radius: 25px; display: flex; height: 50px; justify-content: center; margin-top: 0; margin-top: 30px; position: absolute; right: 0; top: 50%; transform: translateY(-50%); width: 50px; }
               }

ul.list        { margin-bottom:20px; width:100%;
               li        { background:none; font-size: 16px; line-height: 26px; margin: 1em 0; padding:0 0 0 25px; position: relative;
                         ::before { background: #0081cd; border-radius: 3px; content: ""; display: block; height: 6px; left: 5px; position: absolute; top: 10px; width: 6px; }
                         a        { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
                         }
               }

p.panel        { border-top: 1px solid rgba(0,0,0,.1); font:600 18px $fontMain; line-height: 24px; padding:20px 0;
               a   { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
               }

ol             { margin-bottom:40px!important; margin-top:20px!important;
               li  { background:none!important; box-shadow: none!important; margin:0!important; padding:5px 0!important;
                   a { border-bottom: 2px solid #fccf00; color:$colorPrimary; display: inline; font-weight: 700; line-height: 1.2em; }
                   }
               p   { box-shadow: none; margin-bottom:0!important; }
               ::marker { color:$colorPrimary; font-weight:700; font-size:16px; text-align:center; }
               }
#lightgallery  { background:$colorPrimary; padding:60px 0 100px;
               header h2  { color:$color-white; font-size:38px; margin:0 0 20px 0; text-align: center; }
               .item      { padding: 0 10px; }
               figure     { max-height:280px; overflow-y: hidden; }
               figure img { cursor: pointer; /* max-height: 280px; */}
               .slick-prev  { border-radius:30px; border:1px solid $color-white; height:52px; left:-4rem; top:8.5rem; width:52px; z-index:9; }
               .slick-next  { border-radius:30px; border:1px solid $color-white; height:52px; right:-4rem; top:8.5rem; width:52px; z-index:9; }
               }

div.panel      { background:$color-white; border: 1px solid rgba(0,0,0,.1); line-height: 24px; padding:30px 40px; text-align: center; width:100%;
               header h3     { font:400 14px $fontMain; margin:0; text-transform: uppercase; }
               p:first-child { margin:0; }
               }

div.panelinfo  { background:$color-white; border: 1px solid rgba(0,0,0,.1); padding:40px 40px; text-align: center; width:100%;
               p             { font:400 18px $fontMain; letter-spacing: -0.02em; line-height: 30px; margin:0; text-align: center; }
               p:first-child { margin:0; }
               p strong      { font-weight: 600; }
               }


.slick-prev    { border-radius:30px; border:1px solid $color-white; height:52px; left:-4rem; top:8.5rem; width:52px; z-index:9; }
.slick-next    { border-radius:30px; border:1px solid $color-white; height:52px; right:-4rem; top:8.5rem; width:52px; z-index:9; }

.entradeta         { max-width: 48em; margin: 0 auto; text-align: center;
                   p { text-align:center; }
                   }
.callout           { font:600 18px $fontMain; margin:60px 0 0; text-transform: uppercase;
                   .close-button { color: $colorPrimary; font:400 38px $fontMain; }
                   }
.callout.alert     { font:400 28px $fontMain!important;
                   .close-button { color:$color-white!important; font:400 38px $fontMain; }
                   }

#infoForm .legal   { margin:20px 0 0 0;
                   input { display: inline-block; margin-bottom:0!important; }
                   label { max-width:95%; }
                   }



.slick-disabled    { display: none!important; }



/* ------------------------------------------------------------------------------------------------------ */
/* SLIDER */
/* ------------------------------------------------------------------------------------------------------ */

.slider             { background:$color-navy; display: inline-block; margin:-4px 0 0 0; height:100%; width:100%;
                    div [role="main"] { max-width: 35em; padding-top: 350px; position: relative; z-index: 99;
                                      span { border-left: 1px solid hsla(0,0%,100%,.3); color: hsla(0,0%,100%,.7); font-size: 13px; margin-left: 9px; padding: 2px 0 2px 9px; }
                                      a    { border-bottom: 2px solid $color-sunflower; color: $color-white; display: inline; font: 600 12px $fontMain; line-height: 1.2em; text-transform: uppercase; }
                                      div  { margin-bottom:30px; }
                                      p.subtitle { color: $color-white; font-size:18px; text-transform: none; }
                                      a p  { color:$color-white; text-transform: none!important; }
                                      }
                    /* div [role="main"]::after { background-image:linear-gradient(-90deg,rgba(0,21,33,0),#001521); content: ""; display: block; height: 660px; left:auto; position: absolute; top: 0; width: calc(100% + 50px); z-index: 1; } */
                    div [role="article"] { box-sizing: border-box; height: 740px; padding-bottom: 90px; position: relative;
                                      h1  { color:$color-white; font-size:48px; }
                                      img { height: 100%; left:auto; margin:0 8.7rem!important; object-fit: cover; position: absolute; right:calc((1280px - 100vw)/2 - 75px); top: 0; width:calc(100vw - (100vw - 1280px)/2); z-index: 0; }
                                      }
                    .slick-slide { background:$color-navy; }
                    .slick-prev  { border-radius:30px; border:1px solid $color-white; height:52px; left:20rem; top:30rem; width:52px; z-index:9; }
                    .slick-next  { border-radius:30px; border:1px solid $color-white; height:52px; left:24rem; top:30rem; width:52px; z-index:9; }
                    }




@media only screen and (min-width: 900px) and (max-width: 1440px) {}
@media only screen and (min-width: 640px) and (max-width: 900px)  {}
@media only screen and (min-device-width : 1050px) and (max-device-width : 1650px) {}
@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {}
@media only screen and (max-width : 465px) {}

@media only screen and (min-width: 466px) and (max-width : 640px) {
  #topbar           { height: inherit!important; position: fixed; top: 0;
                    #dinamicsponsors { padding:0; }
                    #navigation { min-height:inherit; padding:0; }
                    ul.sponsors        { padding:6px 0; position: absolute; right:20px; text-align: center; top:0; width:auto;
                                       img { width:48px!important; }
                                       }
                    figure#logo-mobile { margin:28px 0 0 -20px; }
                    #emblem            { text-align: center;
                                       img        { margin:0 0 0 -40px; max-width: 52px; }
                                       }
                    #language_selector          { left:0; position:absolute; top:-10px;
                                                nav a      { height:40px; }
                                                nav li > a { padding: 0px 15px 0; }
                                                nav li > a:after { right: -2px; top: 30px; }
                                                nav ul ul { right: inherit; top:55px; }
                                                .dropdown li.is-dropdown-submenu-parent > a { padding:23px 25px 23px 10px; }
                                                }
                    .off-canvas-content .button { position:absolute; right:10px; top:47px; }
                    #btnsmobile                 { background: $color-sunflower!important; margin:12px 0 0 0; width:100%; }
                    #links                      { background: $color-sunflower; float:none!important; margin:0!important; padding:0; text-align: center; width:100%;
                                                li { border-right:1px solid rgba(0,0,0,.2); padding:0!important; width:33.3%!important;
                                                   .btnPrimary { border-radius:0; padding:5px 0!important; width:100%;
                                                               span { font-size:0; }
                                                               }
                                                   }
                                                li:last-child { border:none; }
                                                }
                    .off-canvas-content .button i { font-size:1.5em; }
                    .drilldown                  { min-height:400px; }
                    .drilldown a                { font-size:14px; padding:0.5rem 0; }
                    .drilldown .js-drilldown-back a { font-size:0; padding:10px 20px; }
                    #topcorp                    { background: #fff; margin-top: 45px; width:100%; }
                    .newmainnav                 { padding:0; }
                    }
                    .slider div [role="main"]              { padding-left:20px; padding-top: 420px; }
                    .slider div [role="article"] img       { height:auto!important; margin:0 -10px!important; min-height: 750px; right:0!important; width:100%!important; }
                    .slider .slick-prev { left:30px; }
                    .slider .slick-next { left:90px; }
                    .slider .slick-next, .slider .slick-prev { top:40rem; }
}


.concert { background:#D95F0A; }
.zoco    { background:#8B0AD9; }
.sudor   { background:#0AD9CF; }

#banners { margin:0 30%!important; padding:60px 0;
         ul li { text-align: center; }
         img { max-width:100%!important; }
         }

.relatedlink { margin:20px 0;
             a { text-transform: none; }
             }

.google_calendar { text-align:center;
                 a { background:rgba(0,0,0,.04); border:none!important; color:rgba(0,0,0,.5)!important; font:600 18px $fontMain; margin:10px 0 0 0; padding: 5px 20px; text-align:center; text-transform: none; width:100%;
                   &:hover { background:none; opacity:1; }
                   }
                 p { text-align:center; }
                 }

.comarribar { margin:0;
            img          { float:left; margin-right:8px!important; margin-top: -1px !important; max-width:18px; }
            li           { padding:10px 0 10px 0!important; text-align: left; }
            .ffcc span   { background:#E796BF; color:#fff; font-size:13px; padding:3px 6px; text-transform: uppercase; }
            .metro span  { background:#CB2508; color:#fff; font-size:13px; padding:3px 6px; text-transform: uppercase; }
            .nitbus span { background:#0062A1; color:#fff; font-size:13px; padding:3px 6px; text-transform: uppercase; }
            }
