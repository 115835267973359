
#professional { background:#FAFAFA; margin: 0 0 20px 0; padding:0 0 60px 0;
                header h3 { font:600 28px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; text-transform: uppercase;}
                .llistat {
                         header h1 { font:600 58px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; padding: 0; text-transform: uppercase; }
                         header h2 { font:600 38px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; text-transform: uppercase; }
                         ul { margin:0; }
                         li { background:#fff; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); margin:0 0 20px; padding:25px;
                            figure   { position: relative;
                                     span { color:$color-white; margin:20px 0 0 0!important; padding:5px; position:absolute; text-align:center; z-index: 99999; }
                                     }
                            img      { margin:0; }
                            .large-6 { padding: 30px 30px; }
                            .large-2 { padding: 10px 0 0 0; }
                            header h3 { font-size:36px; line-height: 1.2em; }
                            .sala     { font-weight:600; margin:0; }
                            p         { font:400 14px $fontMain; line-height:1.2em; margin: 0; }
                            .preu_01  { border-bottom:1px solid #979797; padding:0 0 10px 0;
                                      span { display:block; }
                                      }
                            .preu_02  { margin:0 0 20px 0; padding:10px 0 0 0;
                                      span { display:block; }
                                      }
                            a.comprar { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); color:#fff!important; font:600 22px $fontHeaders; margin:0; padding:5px 25px; text-transform: uppercase; }
                            }
                          .tabs         { background: none!important; border:none;
                                        li { background: none!important; border:none!important; box-shadow:none; display:inline-block; margin:0!important; padding:0!important; }
                                        }
                          .tabs-panel   { background: none; border:none; }
                          .tabs-content { background:none; border:none; overflow: hidden; padding:0; width:100%;
                                        ul { display:inline-block; margin:0 0 40px!important; width:100%; }
                                        }
                          #tabsprofessional   { background:none; margin:0 auto 20px; padding:0; text-align: center; width:100%;
                                              .tabs-title  { float:none; }
                                              .tabs-title > a[aria-selected="true"]  { background:#00000020!important; color:#000!important; }
                                              }
                          #tabsprofessional2  { background:none; margin:0 auto 20px; padding:0; text-align: center; width:100%;
                                              .tabs-title  { float:none; }
                                              .tabs-title > a[aria-selected="true"]  { background:#00000020!important; color:#000!important; }
                                              }
                         }
                }

#sala,
#sala2          { background: $color-white!important; height:100%; margin:20px 0 0; padding:60px 60px; width:100%;
                header h2  { margin-bottom:20px; text-align:left!important; }
                figure img { margin:0 0 20px 0; width:100%; }
                .right     { padding-left:60px;}
                }

#salabis        { background: $color-white!important; height:100%; margin:20px 0 0; padding:60px 60px; width:100%;
                header h2  { margin-bottom:20px; text-align:left!important; }
                figure img { margin:0 0 20px 0; width:100%; }
                .right     { padding-left:60px;}
                }

#riders,
#riders2        { padding:60px 0; text-align: center;
                header h3 { text-align:left!important; }
                p         { font-size:18px; margin:0 0 20px 0; text-align:center; }
                ul li     { font:600 20px $fontHeaders; line-height:1.22em; margin:0; text-align: left; text-transform: uppercase; }
                }

#formulario     { background: $color-white; padding:60px;
                header h2 { font-size:42px!important; text-align: center; }
                header h3 { font:400 24px $fontHeaders; margin:0 0 10px 0; text-transform: uppercase; }
                .large-4  { padding:0 40px 0 0;
                          p { line-height:1.4em; margin:0 0 40px 0; }
                          ul { margin:0 0 20px 0;
                             li { box-shadow:none; color:$color-black; font-size:16px; margin:0; padding:4px 0; text-align: left; }
                             }
                          }
                label     { font:400 20px $fontHeaders; margin:0; text-transform: uppercase; }
                input     { border:none; border-bottom:1px solid $color-black; border-radius:0; box-shadow: none; padding:0;
                          :focus { border-bottom:2px solid $color-black!important; }
                          }
                form p    { margin:0 0 40px; }
                textarea  { border:none; border-bottom:1px solid $color-black; border-radius:0; box-shadow: none; margin:0; padding:20px 0 0 0; }
                .btnPrimary { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); border:none; color:#fff!important; font:600 22px $fontHeaders; margin:0; padding:5px 25px; text-transform: uppercase;
                            &:hover { background:$color-black; }
                            }
                }

#virtual,
#virtual2       { padding:60px 0; text-align: center;
                p { font-size:18px; margin:0 0 20px 0; text-align:center; }
                }


#tabsalas       { background:none; border-bottom:1px solid #000!important; margin:0 auto 20px; padding:0 0 20px; text-align: center; width:100%;
                .tabs-title  { float:none; }
                .tabs-title > a[aria-selected="true"] { background: rgb(231,84,0)!important; }
                .tabs-title > a { padding:5px 30px!important }
                }


#sala.is-active,
#sala2.is-active{ display:inline-block; height:100%; }


#formprofesional { background: yellow; height:400px; }









#merch { background:#FAFAFA; margin: 0 0 20px 0; padding:0 0 60px 0;
                header h3 { font:600 28px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; text-transform: uppercase;}
                .llistat {
                         header h1 { font:600 58px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; padding: 0; text-transform: uppercase; }
                         header h2 { font:600 38px $fontHeaders; line-height:1.22em; margin:0 0 20px 0; text-transform: uppercase; }
                         ul { margin:0; }
                         li { background:#fff; box-shadow: 0 2px 4px 0 rgba(0,0,0,.04); margin:0 10px 20px; padding:30px 0 30px 0; text-align: center; width: 23.55%;
                            figure   { margin:0 0 20px 0; max-height:initial; position: relative;
                                     span { color:$color-white; margin:20px 0 0 0!important; padding:5px; position:absolute; text-align:center; z-index: 99999; }
                                     }
                            img      { margin:0; }
                            header h3 { font-size:36px; line-height: 1.2em; margin:0; }
                            .sala     { font-weight:600; margin:0; }
                            p         { font:400 14px $fontMain; line-height:1.2em; margin: 0; text-align: center; }
                            .price    { color:$colorPrimary; font:400 18px $fontMain; letter-spacing: 0.04em; padding:10px 0 20px 0; }

                            a.comprar { background: rgb(231,84,0); background: linear-gradient(90deg, rgba(231,84,0,1) 0%, rgba(204,104,18,1) 100%); color:#fff!important; font:600 22px $fontHeaders; margin:0; padding:5px 25px; text-transform: uppercase; }
                            a.comprar:hover { background: $colorPrimary; }
                            }
                         }
      }

.productimg   {
              .back       { font:600 18px $fontMain; }
              .back:hover { text-decoration: none; }
              }
.productslide { margin-bottom:20px; }
#product      { padding:0 40px!important;
              h1  { margin:0 0 5px!important; padding:0; text-align: left; }
              .ref{ color: $colorSecondary; display:inline-block; font-family: $fontMain; margin:0 0 20px 0; text-transform: uppercase; }
              }
