
.slideshow { margin-top:-87px;
           img { width:100%; }
           }

#manifest  { background: $color-black; padding:80px 20% 80px; position: relative;
           .imagotip  { margin:-175px 25% 0 0; max-width: 15%; right: 0; position: absolute; text-align: center; width: auto;
                      img { max-width:53%; }
                      }
           header h2  { color:$color-white; font:600 42px $fontHeaders; margin:0 0 15px 0; text-transform:uppercase; }
           .subtitle  { color:$color-white; font:600 18px $fontHeaders; letter-spacing: 0.04em; margin:0 0 30px 0; text-transform:uppercase; }
           p          { color:$color-white; font:400 28px $fontMain; line-height: 32px; max-width:55%; }
           }

section#homepage {
                 .llistat ul { margin-left:0;
                             li { padding: 0 10px;
                                figure { margin:0 0 20px 0; max-height: 170px; }
                                .sala  { display: inline-block; width:100%; }
                                }
                             }
                 }

.homegallery { background: $color-black; padding: 0; }
